import { template as template_d05456a7c11f4943acfddc8a803e4162 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d05456a7c11f4943acfddc8a803e4162(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
