import { template as template_706a7c8acefe45be9e1e7572c8a4e5d1 } from "@ember/template-compiler";
const FKText = template_706a7c8acefe45be9e1e7572c8a4e5d1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
