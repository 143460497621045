import { template as template_d483b0044d124d2fa770843afb2af830 } from "@ember/template-compiler";
const FKLabel = template_d483b0044d124d2fa770843afb2af830(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
